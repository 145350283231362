export const QueryKeys = {
  informationDetail: ['information', 'detail'],
  students: ['admin', 'students'],
  teachers: ['admin', 'teachers'],
  chatRoom: ['admin', 'chatRoom', 'chatLogs'],
  information: (query: string = '') => ['information', query],
}

export const MutationKeys = {
  login: ['login'],
  me: ['me'],
  llmModels: ['llmModels'],
  history: ['chatRooms'],
  deleteHistory: ['deleteChatRoom'],
  fetchChatHistory: ['fetchChatHistory'],
  authority: ['authority'],
  chatRooms: ['admin', 'chatRooms'],
  csvDownload: ['admin', 'chatRooms', 'csv'],
}
