export const cookies = () => {
  const convertCookiesStr = (key: string, value: string, expires?: Date) => {
    const expiresStr = expires ? ` expires=${String(expires)}` : ''
    const cookie = `${key}=${encodeURIComponent(value)}; path=/; ${expiresStr}`
    return cookie
  }
  const getCookies = () => {
    const cookies = document.cookie
    return cookies
  }

  const setCookie = (key: string, value: string, expires?: Date) => {
    const cookie = convertCookiesStr(key, value, expires)
    document.cookie = cookie
  }

  const deleteCookie = (key: string) => {
    setCookie(key, '', new Date(1000, 1, 1))
  }

  return {
    setCookie,
    getCookies,
    deleteCookie,
  }
}
