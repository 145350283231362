import { Alert, IconButton } from '@mui/material'
import MuiSnackbar from '@mui/material/Snackbar'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { Flash } from '@/types/common'
import { bgColor, textColor } from '@/utils/themeConfigs/customTheme'
import { ErrorIcon } from '@/components/icon/ErrorIcon'
import { SuccessIcon } from '@/components/icon/SuccessIcon'
import { mediaQuery } from '@/utils/helpers/breakpoint'

export type SnackbarProps = {
  isOpen: boolean
  handleClose: () => void
  message?: string
  vertical?: 'top' | 'bottom'
  horizontal?: 'left' | 'center' | 'right'
  type?: Flash['level']
}

const Wrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  height: 56px;
  z-index: 1000;
  pointer-events: none;
`

const CustomAlert = styled(Alert, {
  shouldForwardProp: (props) => props !== 'snackbarType',
})<{ snackbarType?: Flash['level'] }>`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  color: ${textColor.dark};
  background-color: ${({ snackbarType }) =>
    snackbarType === 'error' ? bgColor.lightRed : bgColor.lightGreen};
  box-shadow:
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.02),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.02),
    0px 6px 6px -3px rgba(0, 0, 0, 0.02),
    0px 12px 12px -6px rgba(0, 0, 0, 0.02),
    0px 24px 24px -12px rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 38px;
  text-align: center;

  & .MuiAlert-icon {
    margin-right: -8px;
    position: absolute;
    left: 16px;
  }

  & .MuiAlert-action {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    align-items: center;
  }
`

const StyledSnackbar = styled(MuiSnackbar)`
  width: 90%;
  ${mediaQuery('sp')} {
    &.MuiSnackbar-root {
      width: 90%;
      left: 5%;
      right: 5%;
    }
  }
`

const CustomIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  & .MuiButtonBase-root {
    margin: 0;
    padding: 0 !important;
  }

  & .MuiSvgIcon-root {
    color: ${bgColor.mediumGray};
  }
`

export const Snackbar: React.FC<SnackbarProps> = ({
  isOpen,
  handleClose,
  message,
  vertical = 'top',
  horizontal = 'center',
  type,
}) => {
  const renderIcon = () => {
    if (type === 'error') {
      return <ErrorIcon />
    }
    if (type === 'success') {
      return <SuccessIcon />
    }
    return undefined
  }

  return (
    <Wrapper>
      <StyledSnackbar
        anchorOrigin={{ vertical, horizontal }}
        open={isOpen}
        onClose={handleClose}
        autoHideDuration={type === 'success' ? 3000 : null}
        key={vertical + horizontal}
      >
        <CustomAlert snackbarType={type} variant='filled' icon={renderIcon()}>
          {message}
          {type === 'error' ? (
            <CustomIconButton size='small' aria-label='close' onClick={handleClose}>
              <CloseIcon fontSize='small' />
            </CustomIconButton>
          ) : undefined}
        </CustomAlert>
      </StyledSnackbar>
    </Wrapper>
  )
}
