import { useCallback } from 'react'
import { useRouter } from 'next/navigation'
import { OAuthProvider, signInWithPopup } from 'firebase/auth'
import jwt from 'jsonwebtoken'
import { useAuthContext } from '@/context/AuthContext'
import { useSnackbarContext } from '@/context/SnackbarContext'
import { useAuth } from '../hooks/useAuth'
import { auth } from '../libs/firebase/client'
import { cookies } from '../helpers/cookie'

export const useAuthentication = () => {
  const { mutateAsync } = useAuth()
  const { setUser } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()
  const { deleteCookie } = cookies()

  const router = useRouter()
  const FIVE_MINUTES = 5 * 60 * 1000

  /**
   * localStorageの有効期限チェック
   * @param exp
   * @returns
   */
  const validExp = useCallback((exp?: number) => {
    if (!exp) return false
    if (exp && Date.now() < exp * 1000) return true
    return false
  }, [])

  const needsIdTokenRefresh = useCallback(
    (exp?: number) => {
      if (!exp) return false
      const diff = Date.now() - exp * 1000
      if (FIVE_MINUTES > diff) return true
      return false
    },
    [FIVE_MINUTES],
  )

  const equalTokenExp = useCallback((token: string, exp?: number) => {
    if (!token || !exp) return false
    const data = jwt.decode(token) as jwt.JwtPayload
    if (data.exp && data.exp === exp) return true
    return false
  }, [])

  /**
   * idTokenの期限が有効か
   * @param token idToken
   * @returns 有効か
   */
  const validToken = (token?: string) => {
    if (!token) return true // MEMO:ログイン時はundefinedで遷移後に値が入ってくる、、、
    const data = jwt.decode(token) as jwt.JwtPayload
    if (data.exp && Date.now() > data.exp * 1000) {
      return false
    }
    return true
  }

  const login = async () => {
    const provider = new OAuthProvider('microsoft.com')
    // シングルテナントの場合はtenantIdの指定が必須
    // default:common
    provider.setCustomParameters({
      tenant: process.env.NEXT_PUBLIC_AZURE_TENANT_ID ?? 'common',
    })

    const res = await signInWithPopup(auth, provider)
    const { user } = res
    const token = await user.getIdToken()
    const { refreshToken } = user

    const response = await mutateAsync(token)

    // tokenの期限をlocalStorageに保持
    const data = jwt.decode(token) as jwt.JwtPayload
    const newExp = data.exp

    localStorage.setItem('AUTH-EXP', newExp?.toString() ?? '0')

    return { response, token, refreshToken }
  }

  const logout = useCallback(async () => {
    await auth.signOut()
    setUser(null)
    localStorage.setItem('AUTH-EXP', '0')
    localStorage.setItem('INFO-UNREAD', '0')
    // CloudFrontの情報
    deleteCookie('CloudFront-Policy')
    deleteCookie('CloudFront-Signature')
    deleteCookie('CloudFront-Key-Pair-Id')

    router.push('/login')
    if (showSnackbar) {
      showSnackbar({
        text: 'ログアウトしました',
        severity: 'success',
      })
    }
  }, [router, setUser])

  return {
    login,
    validToken,
    logout,
    validExp,
    equalTokenExp,
    needsIdTokenRefresh,
  }
}
