import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    success: {
      main: "#1C7AE8",
    },
    error: {
      main: "#D32827",
    },
  },
});
