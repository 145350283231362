import { User as FirebaseUser } from 'firebase/auth'
import { usePathname, useRouter } from 'next/navigation'
import { createContext, useContext, useEffect, useState } from 'react'
import jwt from 'jsonwebtoken'

import { User } from '@/types/user'
import { Info, Me, useGetMe } from '@/utils/hooks/useAuth'
import { auth } from '@/utils/libs/firebase/client'
import { useAuthentication } from '@/utils/hooks/useAuthentication'
import { Loading } from '@/components/uis/Loading/Loading'

import { useInfoContext } from './InfoConrtext'

type AuthProviderProps = {
  children: React.ReactNode
}

type AuthContextProps = {
  setUser: (user: User | null) => void
  user: User | null
}

const AuthContext = createContext<AuthContextProps>({
  setUser: () => {},
  user: null,
})

export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const { logout, validToken, validExp, equalTokenExp, needsIdTokenRefresh } =
    useAuthentication()
  const { mutateAsync } = useGetMe()
  const { setInfoSummary, modal } = useInfoContext()

  const router = useRouter()
  const pathName = usePathname()

  const protectRoute = () => {
    // 管理者権限制御
    if (pathName?.includes('admin') && user?.roleId !== 3) {
      router.replace('/')
    } else {
      // protectにヒットしなかった場合のみ通過して画面描画する
      setIsLoading(false)
    }
  }

  const updateToken = async (
    user: FirebaseUser | null,
    token?: string,
    refreshToken?: string,
  ) => {
    if (token && validToken(token)) {
      // 有効期限内ならtokenをリフレッシュする
      const newToken = await user?.getIdToken(true)
      const response = await mutateAsync(token)
      if (!response || typeof response[0] !== 'object') return

      setUser({
        token: newToken ?? token,
        refreshToken: refreshToken ?? '',
        roleId: typeof response[0] === 'object' ? (response[0] as Me).role_id : -1,
        email: typeof response[0] === 'object' ? (response[0] as Me).email : '',
        infoFlag: typeof response[0] === 'object' ? (response[0] as Me).info_flag : false,
      })

      // nullと空配列の場合はお知らせモーダルを出さない
      // お知らせがある場合はInfo型のObjectが格納されている
      if (response[1] !== null && !Array.isArray(response[1])) {
        setInfoSummary(response[1] as Info)
        const isUnread = localStorage.getItem('INFO-UNREAD')
        if ((!isUnread || isUnread === '0') && (response[0] as Me).info_flag)
          modal?.handleOpen()
      }

      const data = jwt.decode(newToken ?? token) as jwt.JwtPayload
      const newExp = data.exp

      localStorage.setItem('AUTH-EXP', newExp?.toString() ?? '0')
    } else if (token) {
      // tokenの期限切れ
      await logout()
    } else {
      // tokenの取得に失敗？
      await logout()
    }
  }

  useEffect(() => {
    const expStr = localStorage.getItem('AUTH-EXP')
    const exp = Number(expStr)

    if (user) {
      if (pathName === '/login/') {
        router.push('/')
      }
      if (exp && validExp(exp) && equalTokenExp(user.token, exp)) {
        void (async () => {
          await updateToken(auth.currentUser, user.token, user.refreshToken)
          protectRoute()
        })()
      } else {
        void (async () => {
          await logout()
        })()
      }
    } else {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          if (
            exp &&
            validExp(exp) &&
            // TODO: リファクタ
            // @ts-expect-error: user.accessToken:型の割り当てエラー回避
            (equalTokenExp(user.accessToken as string, exp) || needsIdTokenRefresh(exp))
          ) {
            if (pathName === '/login/') {
              router.push('/')
            } else {
              void (async () => {
                // TODO: リファクタ
                // @ts-expect-error: user.accessToken:型の割り当てエラー回避
                await updateToken(user, user.accessToken as string, user.refreshToken)
                protectRoute()
              })()
            }
          } else {
            void (async () => {
              await logout()
            })()
          }
        } else {
          if (pathName !== '/login/') router.push('/login/')
        }
        unsubscribe()
      })
    }
    // TODO: Warning：updateToken, userは含めると無限ループが発生する
  }, [pathName, router, validExp, needsIdTokenRefresh, equalTokenExp])

  return (
    <AuthContext.Provider value={{ setUser, user }}>
      {pathName !== '/login/' ? isLoading ? <Loading centered /> : children : children}
    </AuthContext.Provider>
  )
}
