export const apiVersion = '/v1'

export const API = {
  login: `${apiVersion}/login`,
  me: `${apiVersion}/me`,
  chatGpt: `${apiVersion}/chat-gpt`,
  chatClaude: `${apiVersion}/chat-claude`,
  chatGemini: `${apiVersion}/chat-gemini`,
  chatImage: `${apiVersion}/chat/images`,
  chatModels: `${apiVersion}/chat/models`,
  history: `${apiVersion}/chat-rooms`,
  information: (query?: string) =>
    query ? `${apiVersion}/information?${query}` : `${apiVersion}/information`,
  informationDetail: (id: string) => `${apiVersion}/information/${id}`,

  //admin
  students: (query?: string) =>
    query ? `${apiVersion}/admin/student?${query}` : `${apiVersion}/admin/student`,
  teachers: (query?: string) =>
    query ? `${apiVersion}/admin/teacher?${query}` : `${apiVersion}/admin/teacher`,
  authority: (query: string) => `${apiVersion}/admin/users/authority?${query}`,
  chatRooms: (query?: string) =>
    query ? `${apiVersion}/admin/chat-rooms?${query}` : `${apiVersion}/admin/chat-rooms`,
  csvDownload: (query?: string) =>
    query ? `${apiVersion}/admin/logs/csv?${query}` : `${apiVersion}/admin/logs/csv`,
  chatRoom: (id: string) => `${apiVersion}/admin/chat-rooms/${id}`,
}
