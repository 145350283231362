import { useState, useCallback } from 'react'

export type UesDisclosure = {
  isOpen: boolean
  handleOpen: () => void
  handleClose: () => void
  toggle: () => void
}

export const useDisclosure = (initialState: boolean = false): UesDisclosure => {
  const [isOpen, setIsOpen] = useState<boolean>(initialState)

  const handleOpen = useCallback(() => setIsOpen(true), [])
  const handleClose = useCallback(() => setIsOpen(false), [])
  const toggle = useCallback(() => setIsOpen((prevState) => !prevState), [])

  return { isOpen, handleOpen, handleClose, toggle }
}
