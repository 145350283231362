import { useRouter } from 'next/navigation'
import axios, { AxiosError } from 'axios'
import { useSnackbarContext } from '@/context/SnackbarContext'
import { useAuthContext } from '@/context/AuthContext'
import { auth } from '../libs/firebase/client'
import { baseURL } from '../apis/client'
import { cookies } from '../helpers/cookie'

export const useApiClient = () => {
  const { showSnackbar } = useSnackbarContext()
  const { setUser } = useAuthContext()
  const { deleteCookie } = cookies()
  const router = useRouter()

  const apiClient = (token: string) => {
    const client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    client.interceptors.response.use(
      (response) => {
        if (
          response.status === 200 &&
          typeof response.data === 'string' &&
          response.data !== '' &&
          !(response.request as XMLHttpRequest).responseURL?.includes('csv')
        ) {
          if (showSnackbar)
            showSnackbar({
              text: response.data,
              severity: 'success',
            })
        }
        // MEMO: 検索結果0件でsnackbarを出す場合はここ
        // status204 && response.data === ""で分岐して出す
        return response
      },
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          // useAuthを呼ぶとMaximum call stack size exceeded.になるのでベタでlogout処理を記載
          await auth.signOut()
          setUser(null)
          localStorage.setItem('AUTH-EXP', '0')
          localStorage.setItem('INFO-UNREAD', '0')
          deleteCookie('CloudFront-Policy')
          deleteCookie('CloudFront-Signature')
          deleteCookie('CloudFront-Key-Pair-Id')

          router.push('/login')
        }
        if (
          error.isAxiosError &&
          typeof error.response?.data === 'object' &&
          (error.response.data as { detail: string }).detail
        ) {
          if (showSnackbar)
            showSnackbar({
              text:
                typeof error.response.data === 'object'
                  ? (error.response.data as { detail: string }).detail
                  : 'エラーが発生しました',
              severity: 'error',
            })
        }

        if (
          !error.response?.data ||
          !(error.response?.data as { detail: string }).detail
        ) {
          if (showSnackbar)
            showSnackbar({
              text: 'エラーが発生しました。時間をおいて再度お試しください。',
              severity: 'error',
            })
        }

        return Promise.reject(error)
      },
    )

    return client
  }
  return { apiClient }
}
