import styled from '@emotion/styled'
import { Box, CircularProgress } from '@mui/material'

const FlexBox = styled(Box)`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
`

type LoadingProps = {
  size?: number
  centered?: boolean
}

export const Loading = ({ size = 40, centered = false }: LoadingProps) => {
  return centered ? (
    <FlexBox>
      <CircularProgress size={size} />
    </FlexBox>
  ) : (
    <CircularProgress size={size} />
  )
}
