import { useMutation } from '@tanstack/react-query'
import { MutationKeys } from '../apis/api-keys'
import { API } from '../apis/endpoints'
import { useApiClient } from './useApiClient'

type CloudFrontCookie = {
  'CloudFront-Policy': string
  'CloudFront-Signature': string
  'CloudFront-Key-Pair-Id': string
}

type LoginResponse = {
  role_id: number
  cloudfront_cookies: CloudFrontCookie
}

export const useAuth = () => {
  const { apiClient } = useApiClient()

  return useMutation({
    mutationKey: MutationKeys.login,
    mutationFn: async (token: string) => {
      return await apiClient(token)
        .post<LoginResponse>(API.login, null, {
          headers: {
            'Cache-Control': 'no-cache',
            'Referrer-Policy': 'no-referrer',
          },
        })
        .then((res) => res.data)
        .catch((err) => console.log(err))
    },
    onSuccess: () => {},
    onError: () => {},
  })
}

export type Me = {
  id: string
  role_id: number
  user_type: number
  email: string
  info_flag: boolean
}

export type Info = {
  info_id: string
  title: string
  created_at: string
}

type MeResponse = Array<Me | Info | []>

export const useGetMe = () => {
  const { apiClient } = useApiClient()

  return useMutation({
    mutationKey: MutationKeys.me,
    mutationFn: async (token: string) => {
      return await apiClient(token)
        .get<MeResponse>(API.me)
        .then((res) => res.data)
        .catch((err) => console.log(err))
    },
  })
}
