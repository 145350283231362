import { createContext, useContext } from 'react'
import { Snackbar } from '@/components/uis/Snackbar/Snackbar'
import { SnackbarOptions, useSnackbarState } from '@/utils/hooks/useSnackbarState'

type SnackbarProviderProps = {
  children: React.ReactNode
}

type SnackbarContext = {
  showSnackbar: (({ text, severity }: SnackbarOptions) => void) | undefined
}

const SnackbarContext = createContext<SnackbarContext>({ showSnackbar: undefined })

export const useSnackbarContext = () => useContext(SnackbarContext)

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const { isOpen, message, severity, openSnackbar, closeSnackbar } = useSnackbarState()

  const showSnackbar = ({ text, severity }: SnackbarOptions): void => {
    openSnackbar({ text, severity })
  }

  return (
    <>
      <SnackbarContext.Provider value={{ showSnackbar: showSnackbar }}>
        {children}
      </SnackbarContext.Provider>
      <Snackbar
        isOpen={isOpen}
        handleClose={closeSnackbar}
        message={message}
        type={severity}
      />
    </>
  )
}
