/**
 * APIのベース、環境変数 NEXT_PUBLIC_API_BASE_URL
 * @group API
 * @category 定数変数
 */
let url

switch (process.env.NEXT_PUBLIC_APP_ENV) {
  case 'local':
    url = process.env.NEXT_PUBLIC_LOCAL_IP_ADDRESS ?? 'http://localhost:8000'
    break
  default:
    url = process.env.NEXT_PUBLIC_API_BASE_URL
}

export const baseURL = url

/**
 * ベースURLを取得する関数
 * @group API
 * @category 効用関数
 */
export const getBaseUrl = () => {
  if (baseURL === undefined) console.log('baseURLがセットされていません')
  return baseURL?.charAt(-1) === '/' ? baseURL.substring(0, -1) : baseURL
}
